/* eslint-disable import/no-cycle */
import { useTranslation } from 'next-i18next';
import type { Metrics } from '../../config';
import RewardsGridPure from './RewardsGrid.pure';
import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import type { BrandPage } from '@dx-ui/gql-types';

export type Props = {
  language?: SupportedLanguage;
  metrics?: Metrics;
  shouldSuppressOffers: boolean;
  ctaClassName?: string;
  pageType?: string;
} & BrandPage['mosaicGrid'][0];

const RewardsGrid = (props: Props) => {
  const [t] = useTranslation('common');
  return props.id ? (
    <RewardsGridPure {...props} text={{ more: t('more'), of: t('of', { one: '', two: '' }) }} />
  ) : null;
};
export default RewardsGrid;
