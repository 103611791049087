import type * as React from 'react';
import cx from 'classnames';

export type CaptionProps = {
  /**
   * Optional, provides the text content for the caption
   */
  caption?: string;
  /**
   * Optional link url
   */
  captionLink?: string;
  /**
   * Optional metrics data
   */
  metricsOnClick?: () => void;
};

/**
 * Image caption is an optional prop that is consumed by other components, this cheese does not stand alone. Can be text only or a clickable link.
 */
export const Caption: React.FC<CaptionProps> = ({ caption, captionLink, metricsOnClick }) => {
  const captionClasses =
    'flex absolute top-0 right-0 items-center justify-center flex-auto text-sm mx-4 mt-4 mb-0 py-2 px-4 bg-bg-inverse text-text-overlay opacity-80 p-2 no-underline caption-main-text';

  if (!caption && !captionLink) {
    return null;
  }

  return (
    <figcaption data-testid="caption-container">
      {captionLink ? (
        <a
          data-e2e="linkedCaption"
          data-testid="img-caption-link"
          className={cx(captionClasses, 'cursor-pointer')}
          href={captionLink}
          onClick={() => metricsOnClick?.()}
        >
          {caption}
        </a>
      ) : (
        <div
          data-osc-product="osc-caption"
          className={cx(captionClasses, 'pointer-events-none cursor-default')}
        >
          {caption}
        </div>
      )}
    </figcaption>
  );
};
