/* eslint-disable @typescript-eslint/restrict-template-expressions */
import styled, { css } from 'styled-components';

import { baseTheme } from '../../styles/app/theme';
import breakpoints from '../../utils/helpers/responsive';
import type { SupportedLanguage } from '@dx-ui/framework-i18n';

// Swiper expects certain CSS
const swiperContainerStyles = css`
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  z-index: 1;
  padding: 0 0 6.75rem;

  ${baseTheme.hilton.mqs.smallAndDown`
    padding: 0 0 6rem;
    height: 506px;
  `};
`;

const swiperWrapperStyles = css`
  position: relative;
  width: 100%;
  transform: translate3d(0px, 0, 0);
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
`;

const column = css`
  flex-basis: 100%;
`;

const wrapperColor = '#f5f5f5';
const Wrapper = styled.section<{ suppressOffers: boolean }>`
  background: ${wrapperColor};
  border: 1px solid ${baseTheme.eb.palette.primary4Color};
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.12);
  display: block;
  margin-bottom: 100px;
  max-width: 100%;
  min-width: 978px;
  padding: 0 8px 160px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 1023px) {
    margin-bottom: 40px;
    min-width: 100%;
    padding: 0 8px 0;
  }

  ${({ suppressOffers }) =>
    !suppressOffers &&
    `
      &:after {
        box-shadow: 14px 14px 30px -8px rgba(0, 0, 0, 0.12);
        content: '';
        display: block;
        position: absolute;
        bottom: -27px;
        right: calc(50% - 28px);
        width: 54px;
        height: 54px;
        background: ${wrapperColor};
        border-right: 1px solid ${baseTheme.eb.palette.primary4Color};
        border-bottom: 1px solid ${baseTheme.eb.palette.primary4Color};
        transform: rotate(45deg) skew(-10deg, -10deg);

        ${baseTheme.hilton.mqs.smallAndDown`
          bottom: -33px;
          right: calc(50% - 30px);
          width: 65px;
          height: 65px;
        `};
      }
  `}
`;

const Header = styled.h2`
  font-size: 38px;
  letter-spacing: -1px;
  margin: 80px 0 15px;
  right: calc(50% - 32.5px);
  text-align: center;

  ${baseTheme.hilton.mqs.smallAndDown`
    font-size: 1.875rem;
    letter-spacing: -0.8px;
  `};
`;

const SubHeader = styled.p`
  font-size: 24px;
  margin-bottom: 60px;
  text-align: center;

  ${baseTheme.hilton.mqs.smallAndDown`
    font-size: 1.25rem;
    letter-spacing: -0.2px;
  `};
`;

const Row = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  margin: 0 auto;
  max-width: 1680px;
  position: relative;

  .rewards-slider-container {
    ${swiperContainerStyles};
  }
  .swiper-wrapper {
    ${swiperWrapperStyles};
  }
`;

const Column = styled.div`
  overflow: hidden;
  ${column};
  
  ${baseTheme.hilton.mqs.mediumAndUp`
    &:first-child {
      flex: 0.855;
      @media only screen and (min-width: 649px) and (max-width: 1023px) {
        min-width: 603px;
      }
    `}

    &:last-child {
      flex: 1;
      min-width: 690px;

      ${breakpoints.tabletAndBelow} {
        min-width: 632px;
        flex: 1;
      }
    }
  }

  ${baseTheme.hilton.mqs.smallAndDown`
    flex: none;
    width: 100%;
  `}
`;

const Column24 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2.4;
  max-width: 380px;
  padding-right: 20px;
  &:first-child {
    margin: 0 calc(46% - 350px) 0 calc(54% - 410px);

    @media only screen and (min-width: 1281px) and (max-width: 1440px) {
      margin: 0 calc(45% - 300px) 0 calc(55% - 360px);
    }

    @media only screen and (min-width: 1025px) and (max-width: 1280px) {
      margin: 0 calc(46% - 300px) 0 calc(54% - 355px);
    }

    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      margin: 0 calc(46% - 300px) 0 calc(54% - 355px);
    }

    ${breakpoints.tabletAndBelow} {
      margin: 0 30px 0 50px;
    }
  }

  &:last-child {
    padding-right: 30px;
  }
`;

const Row62 = styled.div`
  flex: 1 1 62%;

  &:nth-child(odd) {
    padding-bottom: 25%;
    ${breakpoints.tabletAndBelow} {
      padding-bottom: 76px;
    }
  }

  &:nth-child(even) {
    padding-top: 25%;
    ${breakpoints.tabletAndBelow} {
      padding-top: 76px;
    }
  }
`;

const Row38 = styled.div`
  flex: 1 1 38%;

  &:nth-child(odd) {
    padding-top: 45px;
  }

  &:nth-child(even) {
    padding-bottom: 45px;
  }
`;

const NavigationWrapper = styled.div`
  display: block;
  height: 32px;
  position: relative;
  width: 100%;
`;

const ButtonText = styled.span<{ language?: SupportedLanguage }>`
  display: block;
  margin-top: -2.125rem;
  text-align: ${({ language }) => (baseTheme.isRTL(language) ? 'right' : 'left')};
`;

const arrowCss = css<{ language?: string }>`
  padding: 16px;
  position: absolute;
  @media only screen and (max-width: 649px) {
    ${ButtonText} {
      visibility: hidden;
    }
  }
`;
type ArrowProps = {
  isVisible: boolean;
  type: string;
  language?: SupportedLanguage;
};

const RightArrow = styled.button<ArrowProps>`
  ${({ isVisible }) =>
    isVisible ? 'display:flex; text-align:center; align-items:center;' : ' display:none'};
  ${({ language }) =>
    baseTheme.isRTL(language) ? 'left: calc(50% - 85px);' : 'right: calc(50% - 85px)'};
  ${arrowCss};
`;

const LeftArrow = styled.button<ArrowProps>`
  ${({ isVisible }) =>
    isVisible
      ? 'display:flex; text-align:center; flex-direction:row-reverse; align-items:center;'
      : 'display:none'};
  ${({ language }) =>
    baseTheme.isRTL(language) ? 'right: calc(50% - 85px)' : 'left: calc(50% - 85px)'};

  ${arrowCss};

  ${ButtonText} {
    text-align: ${({ language }) => (baseTheme.isRTL(language) ? 'right' : 'inherit')};
  }
`;

const GridList = styled.ul``;

const ItemTile = styled.li`
  width: 286px;
`;

const Pagination = styled.div<{ language?: SupportedLanguage }>`
  font-size: 14px;
  margin: 35px 0 35px;
  text-align: center;
  line-height: 30px;

  ${baseTheme.hilton.mqs.mediumAndUp`
    display: none;
  `};
`;

export default {
  ButtonText,
  Column,
  Column24,
  GridList,
  Header,
  ItemTile,
  LeftArrow,
  NavigationWrapper,
  Pagination,
  RightArrow,
  Row,
  Row38,
  Row62,
  SubHeader,
  Wrapper,
};
