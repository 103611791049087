/*
 * app-theme now provides the following media queries via
 * `props.theme.hilton.mqs`
 */

// smallAndUp @media (min-width:0px) {}
// smallAndDown @media (max-width:649px) {}
// small @media (max-width: 649px) {}
// mediumAndUp @media (min-width:650px) {}
// mediumAndDown @media (max-width:949px) {}
// medium @media (min-width: 650px) and (max-width: 949px) {}
// largeAndUp @media (min-width:950px) {}
// largeAndDown @media (max-width:1279px) {}
// large @media (min-width: 950px) and (max-width: 1279px) {}
// xtraLargeAndUp @media (min-width:1280px) {}
// xtraLargeAndDown @media (max-width:1919px) {}
// xtraLarge @media (min-width: 1920px)   {}

const values = {
  desktop: { min: 769, max: 1024 },
  tablet: { min: 601, max: 768 },
  mobile: { min: 320, max: 600 },
};

const breakSmaller = {
  tabletAndBelow: `@media only screen and (max-width: ${values.tablet.max}px)`,
  tabletBelow: `@media only screen and (max-width: ${values.tablet.max - 1}px)`,
  mobile: `@media only screen and (max-width: ${values.mobile.max}px)`,
  smallMobile: `@media only screen and (max-width: ${values.mobile.min}px)`,
};

const breakLarger = {
  desktopLarge: `@media only screen and (min-width: ${values.desktop.max}px)`,
  desktop: `@media only screen and (min-width: ${values.desktop.min}px)`,
  tabletAndUp: `@media only screen and (min-width: ${values.tablet.min}px)`,
  tabletLandscapeAndUp: `@media only screen and (min-width: ${values.desktop.max + 1}px)`,
};

const breakMiddle = {
  tablet: `@media only screen and (min-width: ${values.tablet.min}px) and (max-width: ${values.tablet.max}px)`,
  tabletToDesktop: `@media only screen and (min-width: ${values.desktop.min}px) and (max-width: ${
    values.desktop.max - 1
  }px)`,
};

const queries = {
  ...breakSmaller,
  ...breakMiddle,
  ...breakLarger,
};

export { queries as default };
