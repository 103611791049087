/* eslint-disable @next/next/no-img-element */
import type * as React from 'react';
import cx from 'classnames';

import type { Images } from '../../types/Images';

export type BannerContainerProps = {
  /**  Aria label describing the tile */
  ariaLabel?: string;
  /** Additional classes or styles */
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  /** Backgound image to use per breakpoint */
  images: Images;
  altText?: string | null;
} & React.HTMLAttributes<HTMLDivElement>;

const BannerContainer = ({
  ariaLabel,
  altText,
  children,
  className,
  images,
  ...rest
}: BannerContainerProps) => {
  return (
    <>
      {/* desktop */}
      <div
        aria-label={ariaLabel}
        className={cx(
          'hidden h-64 p-0 md:h-52 md:px-10 md:py-0 lg:flex',
          'bg-transparent bg-cover bg-top bg-no-repeat',
          className
        )}
        data-testid="banner"
        style={{ backgroundImage: `url(${images.desktop})` }}
        {...rest}
      >
        {altText && <img alt={altText} className="sr-only" />}
        {children}
      </div>
      {/* tablet */}
      <div
        aria-label={ariaLabel}
        className={cx(
          'hidden h-64 p-0 sm:flex md:h-52 md:px-10 md:py-0 lg:hidden',
          'bg-transparent bg-cover bg-top bg-no-repeat',
          className
        )}
        style={{ backgroundImage: `url(${images.tablet})` }}
        {...rest}
      >
        {altText && <img alt={altText} className="sr-only" />}
        {children}
      </div>
      {/* mobile */}
      <div
        aria-label={ariaLabel}
        className={cx(
          'h-64 p-0 sm:hidden',
          'bg-transparent bg-cover bg-top bg-no-repeat',
          className
        )}
        style={{ backgroundImage: `url(${images.mobile})` }}
        {...rest}
      >
        {altText && <img alt={altText} className="sr-only" />}
        {children}
      </div>
    </>
  );
};

export default BannerContainer;
