import { css } from 'styled-components';
import { isRtl } from '@dx-ui/utilities-get-language-direction';

// HACK - Get around type errors until all styled-components are removed.
type StyleArgs = Parameters<typeof css<Record<string, any>>>;

export const baseTheme = {
  hilton: {
    breakpoints: {
      small: 650,
      medium: 950,
      large: 1280,
      xtraLarge: 1920,
    },
    mqs: {
      small: (...styles: StyleArgs) =>
        css`
          @media (max-width: 649px) {
            ${css(...styles)}
          }
        `,
      smallAndUp: (...styles: StyleArgs) =>
        css`
          @media (min-width: 0px) {
            ${css(...styles)}
          }
        `,
      smallAndDown: (...styles: StyleArgs) =>
        css`
          @media (max-width: 649px) {
            ${css(...styles)}
          }
        `,
      medium: (...styles: StyleArgs) =>
        css`
          @media (min-width: 650px) and (max-width: 949px) {
            ${css(...styles)}
          }
        `,
      mediumAndUp: (...styles: StyleArgs) =>
        css`
          @media (min-width: 650px) {
            ${css(...styles)}
          }
        `,
      mediumAndDown: (...styles: StyleArgs) =>
        css`
          @media (max-width: 949px) {
            ${css(...styles)}
          }
        `,
      large: (...styles: StyleArgs) =>
        css`
          @media (min-width: 950px) and (max-width: 1279px) {
            ${css(...styles)}
          }
        `,
      largeAndUp: (...styles: StyleArgs) =>
        css`
          @media (min-width: 950px) {
            ${css(...styles)}
          }
        `,
      largeAndDown: (...styles: StyleArgs) =>
        css`
          @media (max-width: 1919px) {
            ${css(...styles)}
          }
        `,
      xtraLarge: (...styles: StyleArgs) =>
        css`
          @media (min-width: 1920px) {
            ${css(...styles)}
          }
        `,
      xtraLargeAndUp: (...styles: StyleArgs) =>
        css`
          @media (min-width: 1280px) {
            ${css(...styles)}
          }
        `,
      xtraLargeAndDown: (...styles: StyleArgs) =>
        css`
          @media (max-width: 1919px) {
            ${css(...styles)}
          }
        `,
    },
  },
  brand: {
    components: {
      grid4x: {
        description: css`
          @media (min-width: 650px) and (max-width: 949px) {
            font-size: 18px;
            line-height: 28px;
          }
        `,
        headline: css`
          color: #104c97;
          font-family: Loew;

          @media (min-width: 950px) {
            margin-bottom: 15px;
            line-height: 50px;
          }

          @media (min-width: 650px) and (max-width: 949px) {
            font-size: 32px;
          }

          @media (max-width: 649px) {
            font-size: 24px;
            line-height: 32px;
          }
        `,
        modal: {
          description: css`
            @media (max-width: 649px) {
              font-size: 16px;
              line-height: 22px;
            }
          `,
          title: css`
            color: #104c97;
          `,
        },
      },
    },
    palette: {
      primaryColor: '#104C97',
      secondaryColor: '#00A8E1',
      tertiaryColor: '#0A3971',
      fontColor: '#1D1D1D',
      primaryBackgroundColor: '#FFFFFF',
    },
    headerTextTransform: 'none',
    typography: {
      bodyFont: 'Proxima N W01 Reg',
      headerFont: 'Loew W00 Medium',
      primaryFontReg: 'Loew W00 Regular',
      primaryFontSemiBold: 'Loew W00 Medium',
      primaryFontBold: 'Loew W00 Bold',
      primaryFontXtraBold: 'Loew W00 ExtraBold',
      primaryFontBlackBold: 'Loew W00 Black',
      primaryFontHeavyBold: 'Loew W00 Heavy',
      secondaryFontReg: 'Proxima N W01 Reg',
      secondaryFontSemiBold: 'Proxima N W01 Smbd',
      secondaryFontBold: 'Proxima N W01 Bold',
      secondaryFontXtraBold: 'Proxima N W01 Xbold',
    },
  },
  breakpoints: {
    large: 1280,
    medium: 768,
    small: 320,
  },
  eb: {
    palette: {
      primary1Color: '#00A8E1',
      primary2Color: '#104C97',
      primary3Color: '#1D1D1D',
      primary4Color: '#FFFFFF',
      primary5Color: '#828282',
      secondary1Color: '#84325E',
      secondary2Color: '#CE3262',
      secondary3Color: '#EF7622',
      tertiaryColor: '#666666',
      quaternaryColor: '#0A3971',
    },
    typography: {
      HeadlineFont: 'Simple Joys',
      primaryFontReg: 'Loew; font-weight: 400;',
      primaryFontBold: 'Loew; font-weight: 700;',
      primaryFontXtraBold: 'Loew W00 ExtraBold',
      primaryFontBlackBold: 'Loew W00 Black',
      primaryFontHeavyBold: 'Loew',
      secondaryFontReg: 'Proxima Nova; font-weight: 400;',
      secondaryFontBold: 'Proxima Nova; font-weight: 700;',
      secondaryFontSemiBold: 'Proxima Nova; font-weight: 600;',
      secondaryFontXtraBold: 'Proxima Nova; font-weight: 800;',
      tertiaryFont: 'Arial',
    },
  },
  name: 'HH',
  animations: {
    curveDefault: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    curveFastLinear: 'all 0.3s cubic-bezier(0.4, 0, 1, 1)',
    hoverOn: 'all 0.2s ease-in',
    hoverOff: 'all 0.2s ease-out',
  },
  box: {
    borderRadius: '4px',
  },
  colors: {
    mono: {
      base: '#1d1d1d',
      black: '#000000',
      light: '#dcdcdc',
      lighter: '#e4e4e4',
      lightest: '#f1f1f1',
      medium: '#666666',
      mediumLight: '#949494',
      mediumLighter: '#bdbdbd',
      white: '#ffffff',
    },
    brand: {
      primary: 'hsl(213,81%,33%)',
      secondary: 'hsl(198,100%,44%)',
      accent: 'hsl(330,58%,33%)',
      accentTwo: 'hsl(0,0%,51%)',
      accentThree: 'hsl(0, 0%, 11%)',
      accentFour: 'hsl(30, 100%, 45%)',
      accentFive: 'hsl(342, 61%, 50%)',
      accentSix: 'hsl(0, 0%, 40%)',
      accentSeven: 'hsl(41, 72%, 51%)',
      accentEight: 'hsl(321, 44%, 32%)',
      black: 'hsl(0,0%,11%)',
      gray: 'hsl(0,0%,67%)',
      lightGray: 'hsl(0,0%,91%)',
      offWhite: 'hsl(0,0%,96%)',
      white: 'hsl(0,0%,100%)',
      transparent: 'transparent',
    },
    state: {
      active: '#009cde',
      alert: '#e57200',
      disabled: '#e8e8e8',
      error: '#d30329',
      inactive: '#979797',
      success: '#2e8540',
      warning: '#e57200',
    },
    typography: {
      bodyCopy: 'black',
      bodyCopyInverse: 'white',
      headline: 'black',
      headlineInverse: 'white',
    },
  },
  spacing: {
    baseUnit: 8,
    padding: {
      xs: '0.25rem',
      s: '0.5rem',
      m: '1rem',
      l: '1.5rem',
      xl: '2rem',
    },
    margin: {
      xs: '0.25rem',
      s: '0.5rem',
      m: '1rem',
      l: '1.5rem',
      xl: '2rem',
    },
  },
  typography: {
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      black: 900,
    },
    body: {
      fontFamily: 'Proxima Nova',
      fontSize: ['0.875rem', '1.25rem', '1.375rem'],
      fontWeight: 'regular',
      lineHeight: 1.19,
      textTransform: 'none',
      sizeOptions: {
        largest: '1.75rem',
        larger: '1.375rem',
        large: '1.25rem',
        medium: '1.125rem',
        small: '1rem',
        smaller: '0.875rem',
        smallest: '0.75rem',
      },
    },
    headlines: {
      h1: {
        fontFamily: 'Simple Joys',
        fontWeight: 'black',
        fontSize: ['3.375rem', '5rem', '6.875rem'],
        lineHeight: 1.25,
        textTransform: 'none',
      },
      h2: {
        fontFamily: 'Loew',
        fontWeight: 'bold',
        fontSize: ['1.5rem', '2rem', '2.625rem'],
        lineHeight: 1,
        textTransform: 'none',
      },
      h3: {
        fontFamily: 'Loew',
        fontWeight: 'semibold',
        fontSize: ['1.375rem', '1.75rem', '2.25rem'],
        lineHeight: 1,
        textTransform: 'none',
      },
      h4: {
        fontFamily: 'Proxima Nova',
        fontWeight: 'bold',
        fontSize: ['1.25rem', '1.625rem', '1.875rem'],
        lineHeight: 1,
        textTransform: 'none',
      },
      h5: {
        fontFamily: 'Proxima Nova',
        fontWeight: 'bold',
        fontSize: ['1.125rem', '1.5rem', '1.625rem'],
        lineHeight: 1,
        textTransform: 'none',
      },
      h6: {
        fontFamily: 'Proxima Nova',
        fontWeight: 'bold',
        fontSize: ['1.125rem', '1.375rem', '1.375rem'],
        lineHeight: 1,
        textTransform: 'uppercase',
      },
    },
  },
  getActiveBreakpoint: { small: 650, medium: 950, large: 1280, xtraLarge: 1920 },
  isRTL: isRtl,
};
