import { css } from 'styled-components';

export const swiperContainerStyles = css`
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  z-index: 1;
`;

export const swiperWrapperStyles = css`
  position: relative;
  width: 100%;
  transform: translate3d(0px, 0, 0);
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
`;

export const swiperSlideStyles = css`
  flex-shrink: 0;
  list-style: none;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
`;

export const SwiperClasses = {
  Container: 'swiper-container',
  Wrapper: 'swiper-wrapper',
  Slide: 'swiper-slide',
  Active: 'swiper-slide-active',
  Visible: 'swiper-slide-visible',
  Duplicate: 'swiper-slide-duplicate',
} as const;
