import NextHead from 'next/head';

type Props = {
  title: string;
  description?: string;
  keywords?: string;
};

export const Head: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  description,
  keywords,
}) => {
  return (
    <NextHead>
      <title key="title">{title}</title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
    </NextHead>
  );
};
