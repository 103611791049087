import type Swiper from 'swiper';

const SWIPER_CLASSES = {
  ACTIVE: 'swiper-slide-active',
};

export function assetPath(filename: string) {
  return `/dx_guests_app/images/${filename}`;
}

export function initSwiper(this: Swiper) {
  if (this.slides) {
    const slides: HTMLElement[] = Array.from(this.slides);
    handleTransitionEnd(slides, true);
  }
}

export function handleTransitionEnd(slides: HTMLElement[], isSlidesInit = false) {
  const setTabIndex = (anchor: HTMLAnchorElement, isActive: boolean) => {
    anchor.tabIndex = isActive ? 0 : -1; // eslint-disable-line no-param-reassign
  };
  slides.forEach((slide: HTMLElement) => {
    const anchors = getAnchorsInSlide(slide);
    const isActive = slide.classList.contains(SWIPER_CLASSES.ACTIVE);

    if (isActive) {
      slide.setAttribute('aria-hidden', 'false');
      const slideHeader = slide.querySelector('.dx-content-header') as HTMLElement;
      if (!isSlidesInit && slideHeader) {
        slideHeader.focus();
      }
    } else {
      slide.setAttribute('aria-hidden', 'true');
    }

    if (anchors.length) {
      anchors.forEach((anchor: HTMLAnchorElement) => {
        setTabIndex(anchor, isActive);
      });
    }
  });
}

function getAnchorsInSlide(slide: HTMLElement): HTMLAnchorElement[] {
  return Array.from(slide.getElementsByTagName('a')) || [];
}
