import { getIsFeatureToggleEnabled } from '@dx-ui/utilities-feature-toggles-configs';
import { DEFAULT_BRAND_CODE, ELIGIBLE_REFRESH_BRAND_CODES } from './constants';

export const ENTERPRISE_THEME_REFRESH_TOGGLE_NAME = 'NHCBP_5821_Enterprise_Refresh';

type FeatureToggles =
  | NonNullable<
      Parameters<typeof getIsFeatureToggleEnabled>[0]['featureToggleData']
    >['featureToggles'];

export function getBrandRefreshProps({
  featureToggles,
  brandCode = DEFAULT_BRAND_CODE,
}: {
  featureToggles: FeatureToggles;
  brandCode?: string;
}) {
  const isBrandRefresh =
    ELIGIBLE_REFRESH_BRAND_CODES.includes(brandCode) &&
    getIsFeatureToggleEnabled({
      featureToggleData: { featureToggles },
      name: ENTERPRISE_THEME_REFRESH_TOGGLE_NAME,
    });
  return { isBrandRefresh };
}
