import type * as React from 'react';
import styled, { css } from 'styled-components';

import breakpoints from '../utils/helpers/responsive';

const containerWidth = '90%';
const containerMaxWidth = '1280px';

const containerOnly = css`
  max-width: ${containerMaxWidth};
  width: ${containerWidth};
  margin: 0 auto;
`;

const withPadding = css`
  padding: 32px 0;
  ${breakpoints.desktop} {
    padding: 60px 0;
  }
`;

const StyledContainer = styled.div`
  ${containerOnly};
`;

const StyledWrapper = styled.div`
  ${withPadding};
`;

const StyledLayout = styled.div<{ centerContent?: boolean }>`
  ${containerOnly};
  ${withPadding};
  text-align: ${(props) => (props.centerContent ? 'center' : null)};
`;

type ComponentProps = {
  children: React.ReactNode;
  alignment?: string;
  centerContent?: string;
};

const Factory =
  (Component: React.ComponentType) =>
  // eslint-disable-next-line react/display-name
  ({ children, ...rest }: ComponentProps) =>
    (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Component {...rest}>{children}</Component>
    );

const Layout = Factory(StyledLayout);
const Wrapper = Factory(StyledWrapper);
const Container = Factory(StyledContainer);

export { Container, Wrapper, Layout as default };
