/* eslint-disable import/no-cycle */
import { Component } from 'react';
import Swiper from 'react-id-swiper/lib/custom';

import RewardsTile from '../RewardsTile/RewardsTile';
import { isRtl as isRtlLanguage } from '@dx-ui/utilities-get-language-direction';
import type { Props as InputProps } from './RewardsGrid';
import Styled from './RewardsGrid.style';
import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import type SwiperInstance from 'swiper';
import Icon from '@dx-ui/osc-icon';

type Props = {
  text: Text;
  shouldSuppressOffers: boolean;
} & InputProps;

export type Text = {
  of: string;
  more: string;
};

export type State = {
  isNextButtonVisible: boolean;
  isPrevButtonVisible: boolean;
};

class MobileGrid extends Component<Props, State> {
  public state = {
    isNextButtonVisible: true,
    isPrevButtonVisible: false,
  };

  private swiper: Swiper = null;

  private getSwiperView = () => {
    const isRtl = isRtlLanguage(this.props.language as SupportedLanguage);
    const options = {
      containerClass: 'rewards-slider-container',
      grabCursor: true,
      navigation: {
        nextEl: '.swiper-button-next.rewards-button-next',
        prevEl: '.swiper-button-prev.rewards-button-prev',
      },
      on: {
        reachBeginning: () => this.swipeHandler(true, false),
        reachEnd: () => this.swipeHandler(false, true),
        slideChange: () => this.swipeHandler(false, false),
      },
      pagination: {
        clickable: true,
        el: '#rewards-pagination',
        type: 'fraction',
        renderFraction: (currentClass: string, totalClass: string) =>
          `<span class="${currentClass}"></span> ${this.props.text.of} <span class="${totalClass}"></span>`,
      },
      renderNextButton: () => (
        <Styled.LeftArrow
          type="button"
          isVisible={this.state.isPrevButtonVisible}
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="swiper-button-prev rewards-button-prev"
          language={this.props.language}
        >
          <Icon name={isRtl ? 'arrow-right' : 'arrow-left'} />
        </Styled.LeftArrow>
      ),
      renderPagination: () => <Styled.Pagination id="rewards-pagination" />,
      renderPrevButton: () => (
        <Styled.RightArrow
          type="button"
          isVisible={this.state.isNextButtonVisible}
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="swiper-button-next rewards-button-next"
          language={this.props.language}
        >
          <Icon name={isRtl ? 'arrow-left' : 'arrow-right'} />
        </Styled.RightArrow>
      ),
      slidesPerView: 'auto',
      spaceBetween: 0,
    };

    const tiles = this.props.items.map((item, index) => (
      <Styled.Column key={item.label}>
        <RewardsTile
          {...item}
          id={`smallItem${index}Column${index}`}
          language={this.props.language}
          ctaClassName={this.props.ctaClassName}
          pageType={this.props?.pageType}
        />
      </Styled.Column>
    ));
    return (
      <Swiper
        {...options}
        ref={(node: HTMLElement & { swiper: SwiperInstance }) =>
          node ? (this.swiper = node.swiper) : (this.swiper = null)
        } // eslint-disable-line no-return-assign
      >
        {tiles}
      </Swiper>
    );
  };

  private swipeHandler = (isBeginning: boolean, isEnd: boolean) => {
    const beginning = !!this?.swiper?.isBeginning;
    const end = !!this?.swiper?.isEnd;
    if (isBeginning) {
      this.setState({ isNextButtonVisible: true, isPrevButtonVisible: false });
    } else if (isEnd) {
      this.setState({ isNextButtonVisible: false, isPrevButtonVisible: true });
    } else if (!beginning && !end) {
      this.setState({ isNextButtonVisible: true, isPrevButtonVisible: true });
    }
  };

  public render() {
    return <>{this.getSwiperView()}</>;
  }
}

export default MobileGrid;
