import { useMemo } from 'react';

import { DynamicGrid } from '@dx-ui/osc-dynamic-grids';
import { useIsClient } from 'usehooks-ts';

import { useSegments } from '../../hooks';

import type { TDynamicGridItem } from '@dx-ui/osc-dynamic-grids';
import type { Metrics } from '../../config';
import type { BrandFourXGrid, BrandFourXGridItem } from '@dx-ui/gql-types';
import type { Link } from '@dx-ui/osc-link';

type Props = BrandFourXGrid & {
  metrics: Metrics;
  className?: string;
  items: BrandFourXGridItem[];
};

const filterItems = (gridItems: BrandFourXGridItem[], segmentIds: string[]) => {
  if (!gridItems?.length) {
    return [];
  }

  const filteredGridItems: BrandFourXGridItem[] = [];

  for (const item of gridItems) {
    if (item?.segmentIds?.length) {
      if (item?.segmentIds?.some((bannerSegmentId) => segmentIds?.includes(bannerSegmentId)))
        filteredGridItems.push(item);
    } else {
      filteredGridItems.push(item);
    }

    if (filteredGridItems.length >= 4) {
      break;
    }
  }

  return filteredGridItems;
};

export const Grid4xCarousel = ({ headline, description, items }: Props) => {
  const isClient = useIsClient();
  const segments = useSegments();

  const filtered4xCarouselItems = useMemo(() => filterItems(items, segments), [items, segments]);

  const mapItems = filtered4xCarouselItems?.map((item) => ({
    id: item?._id || '',
    headline: item?.headline || '',
    itemTitle: item?.label || '',
    imageUrl: item?.imageCompound?.image?.variants?.[1]?.url || '',
    imageAltTxt: item?.imageCompound?.image?.altText || '',
    segmentIds: item?.segmentIds,
    shortDescription: item?.shortDescription || '',
    link: {
      ...item?.link,
      cidParams: {
        campaignId: item?.link?.campaignId as string,
        componentName: 'Grid4xCarousel',
      },
    } as Link,
    modalImageUrl: item?.imageCompound?.expansionPanelImage?.variants?.[1]?.url || '',
  }));

  const isMapItemsInvalid = useMemo(
    () => mapItems?.filter((item) => !!item?.imageUrl)?.length === 0,
    [mapItems]
  );

  if (!isClient || !items || isMapItemsInvalid) {
    return null;
  }

  return (
    <div className="container md:py-4 lg:w-full lg:max-w-full">
      <section className="mb-10 text-center">
        <h3 className="font-headline mb-2 text-4xl font-semibold md:text-3xl">{headline}</h3>
        <p className="mb-12 px-2 text-xl md:mb-4 md:px-0 md:text-2xl">{description}</p>
      </section>
      <DynamicGrid
        items={mapItems as unknown as TDynamicGridItem[]}
        imageAspectRatio="3:4"
        id="rewardsGrid"
      />
    </div>
  );
};
